import React from 'react';
import { Link } from 'react-router-dom';
import Buttons from './Buttons';
import Member from './components/member.png';
import Hardwareteam from './components/hardware-team.jpg';
import Hardmem1 from './components/hardMem1.jpg';
import Hardmem2 from './components/hardMem2.jpg';
import Hardmem3 from './components/hardMem3.jpg';
import Hardmem4 from './components/hardMem4.jpg';
import Hardmem5 from './components/hardMem5.jpg';
import Softmem1 from './components/softmem1.jpg';
import Softmem2 from './components/softmem2.jpg';
import Softmem3 from './components/softmem3.jpg';
import Softmem4 from './components/softmem4.jpg';
import Softmem5 from './components/softmem5.jpg';
import Softmem6 from './components/softmem6.jpg';
import Navmem1 from './components/navmem1.jpg';
import Navmem2 from './components/navmem2.jpg';
import Navmem3 from './components/navmem3.jpg';
import Navmem4 from './components/navmem4.jpg';
import PR1 from './components/pr1.JPEG';
import PR2 from './components/pr2.jpg';
import PR3 from './components/pr3.jpg';
import PR4 from './components/pr4.jpg';
import Pres1 from './components/pres1.jpg';
import Pres2 from './components/pres2.jpg';
import Pres3 from './components/pres3.jpg';
import Pres4 from './components/pres4.jpg';
import Pres5 from './components/pres5.jpg';

import Image from 'react';
import { Text, View } from 'react-native';
import SocialPage from './SocialPage';

function Members() {
  return (
    <div className='bg-gradient-to-br from-slate-950 to-black bg-fixed text-white pb-4 overflow-x-hidden'>
      <Buttons />
      <div>
      <div className='container mx-auto py-4 px-8'>
        <h1 className='title' style={{ fontSize: '50px' }}> Hardware department</h1>
          
        <div style={{ textAlign: 'center' }}>
          <div style={{ display: 'inline-block', margin: '10px' }}>
            <img src={Hardwareteam} alt="Team" height={350} width={500} />
            <p style={{ fontSize: '30px', marginTop: '5px' }}>Team</p>
          </div>
        </div>
        
        <div style={{ textAlign: 'center' }}>
          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={Hardmem1} alt="Hardmem1" height={350} width={250} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Błażej Rozmarynowicz</p>
            {/* Members */}
            <div style={{ position: 'absolute', top: '100%', left: '0', width: '100%' }}>
            </div>
          </div>

          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={Hardmem2} alt="Hardmem2" height={350} width={250} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Călin-Mihai Ghizdaveț</p>
          </div>

          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={Hardmem3} alt="Hardmem3" height={350} width={250} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Victor Cracin</p>
          </div>

          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={Hardmem4} alt="Hardmem4" height={350} width={250} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Flaviu Romanescu</p>
          </div>

          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={Hardmem5} alt="Hardmem5" height={350} width={250} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>William Buitenhuis</p>
          </div>        
        </div>
      </div>

      <div className='maintenanceDepartment' style={{ marginTop: '100px' }}>
        <h1 className='title' style={{ fontSize: '50px' }}>Maintenance department</h1>
                
        <div style={{ textAlign: 'center' }}>
          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={Softmem1} alt="Softmem1" height={350} width={275} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Justin Lungu</p>
            {/* Members */}
            <div style={{ position: 'absolute', top: '100%', left: '0', width: '100%' }}>
            </div>
          </div>

          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={Softmem2} alt="Softmem2" height={350} width={275} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Wojciech Trejter &</p>
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Franciszek Szewczyk</p>
          </div>

          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={Softmem3} alt="Softmem3" height={350} width={275} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Sebastian Gribincea &</p>
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Eduard-Raul Kőntőș</p>
            {/*  */}
          </div>

          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={Softmem4} alt="Softmem4" height={350} width={275} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Matei Florea</p>
          </div>

          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={Softmem5} alt="Softmem5" height={350} width={275} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Luuk van Keeken</p>
          </div>

          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={Softmem6} alt="Softmem6" height={350} width={275} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Izabela Kurek</p>
          </div>
        </div>
      </div>

      <div className='navigationDepartment' style={{ marginTop: '100px' }}>
        <h1 className='title' style={{ fontSize: '50px' }}>Navigation department</h1>
                
        <div style={{ textAlign: 'center' }}>
          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={Navmem1} alt="navmem1" height={350} width={275} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Ash Huang</p>
            {/* Members */}
            <div style={{ position: 'absolute', top: '100%', left: '0', width: '100%' }}>
            </div>
          </div>

          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={Navmem2} alt="navmem2" height={350} width={275} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Dorin-Vlad Udrea</p>
          </div>

          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={Navmem3} alt="Navmem3" height={350} width={275} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Linus Bach</p>
          </div>

          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={Navmem4} alt="Navmem4" height={350} width={275} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>David Vanghelescu</p>
          </div>

          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={Member} alt="Navmem5" height={350} width={300} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Òscar Miró</p>
          </div>
        </div>
      </div>

      <div className='prDepartment' style={{ marginTop: '100px' }}>
        <h1 className='title' style={{ fontSize: '50px' }}>PR department</h1>
                
        <div style={{ textAlign: 'center' }}>
          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={PR1} alt="PR1" height={350} width={250} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Stefania Stelea</p>
            {/* Members */}
            <div style={{ position: 'absolute', top: '100%', left: '0', width: '100%' }}>
            </div>
          </div>

          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={PR2} alt="PR2" height={350} width={250} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Roxana Leta</p>
          </div>

          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={PR3} alt="PR3" height={350} width={250} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Diana Todoran</p>
          </div>

          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={PR4} alt="PR4" height={350} width={250} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Hela Jaworska</p>
          </div>
        </div>
      </div>

      <div className='presentationDepartment' style={{ marginTop: '100px' }}>
        <h1 className='title' style={{ fontSize: '50px' }}>Presentation department</h1>

        <div style={{ textAlign: 'center' }}>
          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={Pres1} alt="Pres1" height={350} width={250} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Richard Harnisch</p>
            {/* Members */}
            <div style={{ position: 'absolute', top: '100%', left: '0', width: '100%' }}>
            </div>
          </div>

          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={Pres2} alt="Pres2" height={350} width={250} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Błażej Rozmarynowicz</p>
          </div>

          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={Pres3} alt="Pres3" height={350} width={250} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Xenia Demetriou</p>
          </div>

          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={Pres4} alt="Pres4" height={350} width={250} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Diana Catana</p>
          </div>

          {/* Team */}
          <div style={{ display: 'inline-block', margin: '10px', verticalAlign: 'top',  }}>
            {/* Leader */}
            <img src={Pres5} alt="Pres5" height={350} width={250} />
            <p style={{ fontSize: '25px', marginTop: '5px' }}>Viki Simion</p>
          </div>
        </div>
        </div>
      </div>
      <SocialPage/>
    </div>
  );
}

export default Members;
