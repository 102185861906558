import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from './components/Logo_white_Transp.png';
import "./style.css"

function Buttons(){
  const [showMenu, setShowMenu] = useState(false);

  const googleForm = () => {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSd66iYp82TEGhTLoFU33sGR2sLxqZeS5gGcs5GkCE-A07MdKA/viewform?usp=sf_link', '_blank');
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return(
    <nav class="bg-gradient-to-br from-slate-900 to-black bg-fixed text-white pb-4 object-cover"
      style={{ 
        textAlign: "center", 
        padding: "0.5rem",
        position: 'sticky',
        top: '0', 
        height: '110px', 
        }}
      >
        <div className="bar" 
          style={{ 
            maxWidth: "auto", 
            maxHeight: "fit-content", 
            margin: "0 auto", 
            padding: "1rem", 
            display: "flex", 
            alignItems: "center", 
            justifyContent: "space-between" 
            }}
          >
          <div className="picture" 
            style={{ 
              width: "150px", 
              display: "flex", 
              alignItems: "center" 
              }}
            >
            <img src={logo} alt="Picture with the Makercie team logo" 
            style={{ 
              maxWidth: "100%", height: "auto" 
              }} 
            />
          </div>
          {window.innerWidth > 768 ? (
          <div className="buttons" 
            style={{ 
              display: "flex", 
              justifyContent: "flex-end", 
              gap: "2rem" 
            }}
          >
            <Link to="/" className="button" class="bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-pink-500 hover:to-yellow-500"
              style={{ 
                padding: "0.5rem 1rem", 
                color: "#fff", border: "none", 
                borderRadius: "4px", 
                cursor: "pointer", 
                textDecoration: "none",
                display: "flex", 
                alignItems: "center",  
              }}
            >Home</Link>

            <Link to="/about" className="button" class="bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-pink-500 hover:to-yellow-500"
              style={{ 
                padding: "0.5rem 1rem", 
                color: "#fff", border: "none", 
                borderRadius: "4px", 
                cursor: "pointer", 
                textDecoration: "none",
                display: "flex", 
                alignItems: "center",  
              }}
            >About Us</Link>

            <Link to="/competitions" className="button" class="bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-pink-500 hover:to-yellow-500"
              style={{ 
                padding: "0.5rem 1rem", 
                color: "#fff", border: "none", 
                borderRadius: "4px", 
                cursor: "pointer", 
                textDecoration: "none",
                display: "flex", 
                alignItems: "center",  
              }}
            >News & Competitions</Link>

            <Link to="/members" className="button" class="bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-pink-500 hover:to-yellow-500"
              style={{ 
                padding: "0.5rem 1rem", 
                color: "#fff", border: "none", 
                borderRadius: "4px", 
                cursor: "pointer", 
                textDecoration: "none",
                display: "flex", 
                alignItems: "center",  
              }}
            >Members</Link>
            
            
            <Link to="/gallery" className="button" class="bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-pink-500 hover:to-yellow-500"
              style={{ 
                padding: "0.5rem 1rem", 
                color: "#fff", border: "none", 
                borderRadius: "4px", 
                cursor: "pointer", 
                textDecoration: "none",
                display: "flex", 
                alignItems: "center",  
              }}
            >Gallery</Link>
            
            
            <Link to="/" onClick={googleForm} className="button" class="bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-pink-500 hover:to-yellow-500"
              style={{ 
                padding: "0.5rem 1rem", 
                color: "#fff", border: "none", 
                borderRadius: "4px", 
                cursor: "pointer", 
                textDecoration: "none",
                display: "flex", 
                alignItems: "center",  
              }}
            >Join Us</Link>
          </div>): (
          <div>
            <button
              onClick={toggleMenu}
              className="button bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-pink-500 hover:to-yellow-500"
              style={{
                padding: '0.5rem 1rem',
                color: '#fff',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Menu
            </button>

            {showMenu && (
              <div className="buttons" style={{ marginTop: '0.5rem' }}>
                <Link
                  to="/"
                  className="button bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-pink-500 hover:to-yellow-500"
                  style={{
                    padding: '0.5rem 1rem',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Home
                </Link>

                <Link
                  to="/about"
                  className="button bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-pink-500 hover:to-yellow-500"
                  style={{
                    padding: '0.5rem 1rem',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  About Us
                </Link>

                <Link
                  to="/competitions"
                  className="button bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-pink-500 hover:to-yellow-500"
                  style={{
                    padding: '0.5rem 1rem',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  News & Competitions
                </Link>

                <Link
                  to="/members"
                  className="button bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-pink-500 hover:to-yellow-500"
                  style={{
                    padding: '0.5rem 1rem',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Members
                </Link>

                <Link
                  to="/gallery"
                  className="button bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-pink-500 hover:to-yellow-500"
                  style={{
                    padding: '0.5rem 1rem',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Gallery
                </Link>

                <Link
                  to="/"
                  onClick={googleForm}
                  className="button bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-pink-500 hover:to-yellow-500"
                  style={{
                    padding: '0.5rem 1rem',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Join Us
                </Link>
              </div>
            )}
          </div>
        )}
        </div>
      </nav>
    
)
}

export default Buttons;

//Colors: 4e9ecb, c60f7b, 340068, 004777, d6f8d6