import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './scripts/Home';
import AboutUs from './scripts/AboutUs';
import Competitions from './scripts/Competitions';
import Gallery from './scripts/Gallery';
import Members from './scripts/Members'

function App() {
  return (
    <Router>  
      <div>
        {/* Routing */}
        <Routes>
          <Route path="/about" element={<AboutUs />} />
          <Route path="/competitions" element={<Competitions />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/members" element={<Members />} />
          <Route path="/" element={<Home />} /> {/* Default route */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
