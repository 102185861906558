import ReactModal from 'react-modal';
import React, { useState } from 'react';
import "./style.css"
import Buttons from './Buttons';
import SocialPage from './SocialPage';

const deanImages = [
    require('./components/dean1.jpg'),
    require('./components/dean2.jpg'),
    require('./components/dean3.jpg'),
    require('./components/dean4.jpg'),
    require('./components/dean5.jpg'),
    require('./components/dean6.jpg'),
    require('./components/dean7.jpg'),
    require('./components/dean8.jpg'),
    require('./components/dean9.jpeg'),
    require('./components/dean10.jpeg'),
    require('./components/dean11.jpeg'),
    require('./components/dean12.jpeg'),
  ];
  
  const drachtenImages = [
    require('./components/dr1.jpg'),
    require('./components/dr2.jpg'),
    require('./components/dr3.jpg'),
    require('./components/dr4.jpg'),
    require('./components/dr5.jpg'),
    require('./components/dr6.jpg'),
    require('./components/dr7.jpg'),
    require('./components/dr8.jpg'),
    require('./components/dr9.png'),
    require('./components/dr10.png'),
    require('./components/dr11.png'),
    require('./components/dr12.png'),
    require('./components/dr13.png'),
    require('./components/dr14.png'),
    require('./components/dr15.png'),
    require('./components/dr16.png'),
    require('./components/dr17.png'),
  ];
  
  const meetingImages = [
    require('./components/meet1.jpg'),
    require('./components/meet2.jpg'),
    require('./components/meet3.jpg'),
    require('./components/meet4.jpg'),
    require('./components/meet5.jpg'),
    require('./components/meet6.jpg'),
    require('./components/meet7.jpeg'),
    require('./components/meet8.jpeg'),
    require('./components/meet9.jpeg'),
    require('./components/meet10.jpeg'),
    require('./components/meet11.jpeg'),
    require('./components/meet12.jpeg'),
  ];
  
  const assenImages = [
    require('./components/assen1.jpg'),
    require('./components/assen2.jpg'),
    require('./components/assen3.jpg'),
    require('./components/assen4.png'),
    require('./components/assen5.png'),
    require('./components/assen6.jpg'),
    require('./components/assen7.jpg'),
    require('./components/assen8.jpg'),
    require('./components/assen9.jpg'),
    require('./components/assen10.jpg'),
    require('./components/assen11.jpg'),
    require('./components/assen12.jpeg'),
    require('./components/assen13.jpeg'),
    require('./components/assen14.jpeg'),
    require('./components/assen15.jpeg'),
    require('./components/assen16.jpeg'),
    require('./components/assen17.jpeg'),
    require('./components/assen18.jpeg'),
    require('./components/assen19.jpeg'),
    require('./components/assen20.jpeg'),
    require('./components/assen21.jpeg'),
    require('./components/assen22.jpeg'),
  ];
  

function ImageGallery({ images, category }) {
  const [isOpen, setIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
  
    const openModal = (image) => {
      setSelectedImage(image);
      setIsOpen(true);
    };
  
    const closeModal = () => {
      setSelectedImage(null);
      setIsOpen(false);
    };
  
    return (
      <div className={category.toLowerCase()} class="container mx-auto px-8" style={{ marginTop: '100px' }}>
        <h1 className=" text-white pb-4 " style={{ fontSize: '50px' }}>
          {category}
        </h1>
  
        <div style={{ textAlign: 'center' }}>
          {images.map((image, index) => (
            <div
              key={index}
              style={{
                display: 'inline-block',
                margin: '5px',
                verticalAlign: 'top',
              }}
            >
              <img
                src={image}
                alt={`${category}-${index}`}
                height={350}
                width={350}
                onClick={() => openModal(image)}
              />
              <div style={{ position: 'absolute', top: '100%', left: '0', width: '100%' }}></div>
            </div>
          ))}
        </div>
        
        <ReactModal
          isOpen={isOpen}
          contentLabel="News Modal"
          onRequestClose={closeModal}
          style={{ zIndex: 15 }}
        > 
          <div className="flex justify-end">
            <button className="closeButton" onClick={closeModal}>
              X
            </button>
          </div>

          {selectedImage 
            ? (
              <div className="h-full pb-8">
                <img
                  className="h-full object-cover mx-auto"
                  src={selectedImage}
                />
              </div> 
            )
            : undefined
          }
        </ReactModal>
      </div>
    );
  }
  

function Gallery() {
  return (
    <div className=' bg-gradient-to-br from-slate-900 to-slate-950 bg-fixed overflow-x-hidden'>
      <Buttons />
      
      <ImageGallery images={deanImages} category="Meeting with the Dean" />
      <ImageGallery images={drachtenImages} category="Drachten 03.06.2023" />
      <ImageGallery images={meetingImages} category="Meeting with the team" />
      <ImageGallery images={assenImages} category="UR3E (Assen)" />

      <SocialPage/>
    </div>
  );
}

export default Gallery;