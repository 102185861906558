import ReactModal from 'react-modal';
import React, { useState } from 'react';
import Buttons from './Buttons';
import SocialPage from './SocialPage';
import teamPicture from './components/newCrop2.png'
import './style.css'; // Import the CSS file
import NewsWidget from './NewsWidget';

function Home() {
  return (
      <div className="overflow-x-hidden">
      <div>
        <Buttons />
        
        <div className="bg-gradient-to-br from-slate-900 to-slate-950 bg-fixed w-screen">
          <img
              src={teamPicture}
              alt="Team Picture"
              class="h-full w-full object-cover"
            />
        </div>

        <div className="bg-gradient-to-br from-slate-900 to-slate-950 bg-fixed text-white pb-4">
          <div className="container mx-auto py-4">
            <div className="flex flex-col gap-8 px-8">
              <div>
                <h1 className="text-5xl font-bold mb-4">
                  Representing our university one gear at a time
                </h1>
                
                <p className='text-xl'>
                  Over ten months ago, a small group of six students set their sights on the European Rover Challenge, growing into what now is the Makercie Rover Team. With nearly 50 members, Makercie is the first ever Dutch team to participate in the ERC. Our team members come from many different fields of study, including Physics, Engineering, Chemistry, Astronomy, Mathematics, Artificial Intelligence, Computer Science, Law, International Business and more. We also come from many different national backgrounds, and we have all come together to represent our University.
                </p>
              </div>

              <div>
                <h1 className="text-3xl font-bold mb-4">
                  Navigation Department
                </h1>
                
                <p className='text-xl'>  
                The Navigation department is concerned with completing the Navigation task. It includes building a model rover, which we 3D-printed and assembled ourselves, including all the electronics and mechanical parts. During the competition the navigation team pilots create a path plan for the rover to be able to scan the QR markers and avoid any danger present at the simulated “Moon” terrain. This is done by observing the terrain around the rover with a camera located inside of it, and steering the rover remotely from a distance, to better replicate the experience of a real space exploration mission. 

                </p>
              </div>

              <div>
                <h1 className="text-3xl font-bold mb-4">
                  Presentation Department
                </h1>
                
                <p className='text-xl'>
                The Presentation department creates the deliverables required by the competition, like reports about the progress of the team, as well as videos documenting our work.
                </p>
              </div>

              <div>
                <h1 className="text-3xl font-bold mb-4">
                  Maintaince Team
                </h1>
                
                <p className='text-xl'>
                The Maintenance department is responsible for completing the maintenance task as required by the competition. Our proposed software architecture for the Mars Rover is designed with a focus on autonomy, modularity, and maintenance. This approach not only mitigates potential issues down the line but also allows our team members to work independently and efficiently on various aspects of the rover system. The software architecture is structured around several core, interconnected modules, each playing a crucial role in the overall system, which includes moving a UR3 robotic arm, scanning QR tags, and operating panels of buttons to adjust different systems in the “Moon” environment.
                </p>
              </div>

              <div>
                <h1 className="text-3xl font-bold mb-4">
                  Non-Technical Team
                </h1>
                
                <p className='text-xl'>
                The Non technical department includes the HR, PR and acquisition team. The first manages the intake of members and the internal structure of the team. The second organizes the social media and public appearances of the team, as well as the promotional material. Last but not least, the acquisition team manages our budget, spending and sponsorships, ensuring the work goes as smoothly as possible with no issues with lacking components and materials. 
                </p>
              </div>
            </div>
          </div>
        </div>


        <SocialPage />
      </div>
    </div>
  );
}

export default Home;


{/*
Representing our university one gear at a time

Over ten months ago, a small group of six students set their sights on the European Rover Challenge, growing into what now is the Makercie Rover Team. With nearly 50 members, Makercie is the first ever Dutch team to participate in the ERC. Our team members come from many different fields of study, including Physics, Engineering, Chemistry, Astronomy, Mathematics, Artificial Intelligence, Computer Science, Law, International Business and more. We also come from many different national backgrounds, and we have all come together to represent our University. 

The Maintenance department is responsible for completing the maintenance task as required by the competition. Our proposed software architecture for the Mars Rover is designed with a focus on autonomy, modularity, and maintenance. This approach not only mitigates potential issues down the line but also allows our team members to work independently and efficiently on various aspects of the rover system. The software architecture is structured around several core, interconnected modules, each playing a crucial role in the overall system, which includes moving a UR3 robotic arm, scanning QR tags, and operating panels of buttons to adjust different systems in the “Moon” environment.

The Navigation department is concerned with completing the Navigation task. It includes building a model rover, which we 3D-printed and assembled ourselves, including all the electronics and mechanical parts. During the competition the navigation team pilots create a path plan for the rover to be able to scan the QR markers and avoid any danger present at the simulated “Moon” terrain. This is done by observing the terrain around the rover with a camera located inside of it, and steering the rover remotely from a distance, to better replicate the experience of a real space exploration mission. 

The Presentation department creates the deliverables required by the competition, like reports about the progress of the team, as well as videos documenting our work.

The Non technical department includes the HR, PR and acquisition team. The first manages the intake of members and the internal structure of the team. The second organizes the social media and public appearances of the team, as well as the promotional material. Last but not least, the acquisition team manages our budget, spending and sponsorships, ensuring the work goes as smoothly as possible with no issues with lacking components and materials. 

*/}