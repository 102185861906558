
import React from 'react';
import { Link } from 'react-router-dom';
import Buttons from './Buttons';
import './style.css'; // Import the CSS file
import SocialPage from './SocialPage';


function AboutUs() {
  return (
    <div className='bg-gradient-to-br from-slate-900 to-slate-950 bg-fixed text-white pb-4 overflow-x-hidden'>
      <Buttons />
        <div className='container mx-auto py-4'>
          <div class='mb-8 px-8'>
            <h1 className="text-5xl font-bold mb-4 mt-8"> Who are we?</h1>
            <p className='text-xl'>
            Over ten months ago, a small group of six students set their sights on the European Rover Challenge, growing into what now is the Makercie Rover Team. With nearly 50 members, Makercie is the first ever Dutch team to participate in the ERC. Our team members come from many different fields of study, including Physics, Engineering, Chemistry, Astronomy, Mathematics, Artificial Intelligence, Computer Science, Law, International Business and more. We also come from many different national backgrounds, and we have all come together to represent our University. 
            <br></br>
            <br></br>
            Supervised by Dr. Bahar Haghighat, the team is actively managed by its student members who come from various international and educational backgrounds. We have also gained support from the Engineering and Technology Institute Groningen (ENTEG) and its director Bayu Jayawardhama, the Faculty of Science and Engineering at RUG and several Dutch companies. (see Sponsor Page). We have also gained the support of the study associations at RUG, including FMF, T.F.V. 'Professor Francken' and Sirius A. 
            <br></br>
            <br></br>
            We are currently preparing for the finals of the Remote Edition of the ERC, for which we qualified with first place out of 17 teams. 
            <br></br>
            <br></br>
            Our team is based in Groningen in the Netherlands, and our team members are students from the Rijksuniversiteit Groningen and Hanze University of Applied Sciences. RUG is one of the oldest educational institutions in the Netherlands and has harbored many successful scientists and Nobel prize winners. Hanze is the largest vocational university in the northern Netherlands, and was established in 1986. This collaboration has allowed us to create a diverse team of students, perfectly suited to this new and exciting challenge! 
            <br></br>
            <br></br>
            The team's current objective is to win the ERC 2023 Remote edition and participate in the following year's On-site version of the competition. The cost of these efforts is estimated to reach around 20000 euros, taking into account both remote-rover and software. The project also necessitates a workspace. While the team has been generously given access to the DTPA laboratory on the Zernike campus, building a one cubic meter rover for the on-site edition requires a much larger, permanent space. Following the successful precedent set by universities such as Delft and EPFL, we hope to establish a long-standing student association that can represent the university in robotics competitions worldwide, and provide students with an opportunity to gain practical robotics experience. 
            </p>
          </div>
        </div>
        <SocialPage/>
    </div>
  );
}

export default AboutUs;

// Similarly, create other component files for AboutUs, Competitions, Gallery, and JoinOurTeam.
