import React from 'react';
import { Link } from 'react-router-dom';
import Buttons from './Buttons';
import SocialPage from './SocialPage';
import marsYard from './components/marsYard.png'
import rank from './components/rank.png'
import winners from './components/3377.png'
import minister1 from './components/1Min.jpeg'
import minister2 from './components/2Min.jpeg'
import minister3 from './components/3Min.jpeg'
import minister4 from './components/4Min.jpeg'

function Competitions() {
  return (
    <div class='bg-gradient-to-br from-slate-900 to-slate-950 bg-fixed text-white pb-4 h-auto w-full overflow-x-hidden'>
      <Buttons />
      <div className='container mx-auto py-4'>
        <div class='row-auto mt-8 h-auto'>

        <div class = 'container rounded-lg bg-gradient-to-br from-slate-600 to-slate-800 bg-fixed h-auto my-8'>
            <h1 class ='text-5xl font-bold mb-4 text-center pt-8 px-4'>
            Meeting with the Dutch Minister of Economic Affairs and Climate Policy
            </h1>
            <p class ='text-xl font-medium mb-4 pt-1 px-12 text-right'>11.10.2023, Groningen, Netherlands</p>
            <p class ='text-xl font-medium mb-4 pt-4 px-12 pb-8'>
            Hello Rover enthusiasts! We recently had the distinct honor of welcoming a distinguished guest. On the 9th of October, 2023, the <span class="font-extrabold">Dutch Minister of Economic Affairs and Climate Policy, Micky Adriaansens</span>, paid a visit to Groningen, and her visit left an indelible mark on us. Here's a recap of this momentous day:
            <br></br>
            <br></br>
            To kick off the day, we extended a warm welcome to Minister Micky Adriaansens and her entourage. Selected members of Makercie were given the unique opportunity to <span class="font-extrabold">present in front of the Minister</span>. We spoke about our team, our aspirations, and the incredible journey of building a rover from scratch. It was a chance to share our passion and showcase the innovation and dedication that define Makercie.
            <br></br>
            <br></br>
            One of the most exciting moments of the day was when Minister Adriaansens had a <span class="font-extrabold">hands-on experience with our rover</span>. She got behind the controls as her enthusiasm and curiosity were truly inspiring. Besides this, it was also an opportunity for us to build bridges. We contacted companies who also participated at the presentation, exploring <span class="font-extrabold">collaboration possibilities</span> with Makercie. This opens up exciting avenues for future projects and partnerships.
            <br></br>
            <br></br>
            We, at Makercie, would like to express our deep gratitude to Minister Micky Adriaansens for her time, interest, and enthusiasm. Her support and encouragement mean the world to us as we continue our journey in rover making and contributing to the fields of science and technology. Stay tuned for more exciting updates from us.
            <br></br>
            <br></br>
            Makercie.             

            </p>
            <div className='grid grid-cols-2'>
              <div class='grid place-content-center mb-4 pt-4 px-12 pb-8'>
                <img src={minister1} class = 'rounded-lg h-96 mx-auto'></img>
              </div>
              <div class='grid place-content-center mb-4 pt-4 px-12 pb-8'>
                <img src={minister3} class = 'rounded-lg h-96 mx-auto'></img>
              </div>
            </div>
          </div>

          
          <div class = 'container rounded-lg bg-gradient-to-br from-slate-600 to-slate-800 bg-fixed h-auto my-8'>
            <h1 class ='text-5xl font-bold mb-4 text-center pt-8 px-4'>
            Winners Of European Rover Challenge (ERC)
            </h1>
            <p class ='text-xl font-medium mb-4 pt-1 px-12 text-right'>29.09.2023, Groningen, Netherlands</p>
            <p class ='text-xl font-medium mb-4 pt-4 px-12 pb-8'>
            We are thrilled to share some incredible news with you all! On the 17th of September, 2023, Makercie achieved a remarkable feat by <span class="font-extrabold">winning the 9th edition of the European Rover Challenge (ERC)</span> remotely! This achievement is a testament to our unwavering dedication, teamwork, and a strong spirit of innovation.
            <br></br>
            <br></br>
            Our dedication to excellence was further recognized when we were awarded for the <span class="font-extrabold">Best Presentation</span>. This honor underscores our commitment to clear communication and the ability to effectively convey the essence of our rover project.
            <br></br>
            <br></br>
            Moreover, in a competition filled with more experienced teams, we rose to the occasion and emerged as the champions. This victory not only speaks volumes about our technical prowess, but also our ability to learn and adapt rapidly.
            <br></br>
            <br></br>
            Makercie has always been about more than just winning. Makercie is about the journey and the lessons learned along the way. ERC 2023 was a great learning experience, teaching us valuable skills that will serve us well in our future challenges.
            <br></br>
            <br></br>
            <span class="font-extrabold">
            The journey doesn't stop here! We are more determined than ever to push our boundaries and explore new horizons. We have our sights set on the ERC on-site competition in 2024 and the University Rover Challenge (URC).</span>
            <br></br>
            <br></br>
            At this juncture, we want to express our heartfelt gratitude to our sponsors and supporters. Your unwavering faith in our capabilities has been instrumental in our success.
            <br></br>
            <br></br>
            We are immensely proud of what we've achieved and we are looking forward to sharing even more successes with all of you in the future.
            <br></br>
            <br></br>
            Makercie.             

            </p>
            <div class='grid place-content-center mb-4 pt-4 px-12 pb-8'>
              <img src={winners} class = 'rounded-lg h-96 mx-auto'></img>
            </div>
          </div>

          <div class = 'container rounded-lg bg-gradient-to-br from-slate-600 to-slate-800 bg-fixed h-auto my-8 '>
            <h1 class ='text-5xl font-bold mb-4 text-center pt-8 px-4'>
              Elysium Site at University of Groningen
            </h1>
            <p class ='text-xl font-bold mb-4 pt-1 px-12 text-right'>05.09.2023, Groningen, Netherlands</p>
            <p class ='text-xl font-medium mb-4 pt-4 px-12 pb-8'>
            The Makercie team has undertaken a new, exciting project at the Zernike Campus at the University of Groningen. With the start of the new academic year we undertook the building of a model of a Mars Yard, modelled after the ones available in at the on-site European Rover Challenge and the Elysium Planitia, the second largest volcanic region on Mars. 
            <br></br>
            <br></br>
            Our version is located in the Energy Academy, and has an area of around 70 square meters. Upon completion of the building process it will feature a model of a volcano, a dry riverbed and a variety of different terrain types, from rocky to sandy.           
            <br></br> 
            <br></br>
            The construction of this site will allows us to test our rover in conditions as close as possible to the ones we can expect during the remote and on-site ERC competitions, and is sure to give us additional knowledge with respect to the design and improvements we can make to our rover. 
            The site it scheduled to be opened in November 2023. Make sure to follow us, so you don't miss it!
            <br></br>
            <br></br>
            See you in the stars!
            <br></br>
            <br></br>
            Makercie.             

            </p>
            <div class='grid place-content-center mb-4 pt-4 px-12 pb-8'>
              <img src={marsYard} class = 'rounded-lg h-96 mx-auto'></img>
            </div>
          </div>
          
          <div class = 'container rounded-lg bg-gradient-to-br from-slate-600 to-slate-800 bg-fixed h-auto my-8'>
            <h1 class ='text-5xl font-bold mb-4 text-center pt-8 px-4'>
            Makercie's First Place in the ERC Qualification
            </h1>
            <p class ='text-xl font-bold mb-4 pt-1 px-12 text-right'>05.09.2023, Groningen, Netherlands</p>
            <p class ='text-xl font-medium mb-4 pt-4 px-12 pb-8'>
            As a new and enthusiastic team, we at Makercie have reached another incredible milestone in our #ERC2023 journey, by placing first in Qualification B of the European Rover Challenge. Coming in at 15 points ahead of other, more established and experienced teams, we took first place with an amazing 93 out possible 100 points, and therefore qualifying for the finals.
            <br></br>
            <br></br>
            As a part of the upcoming Remote Edition of the European Rover Challenge our team had to complete the second out of three qualifications. We were graded on our Maintenance software, our performance in the Navigation task, and our promotional video, showing our team, work and progress.
            <br></br>
            <br></br> 
            During the qualification we had the opportunity to test our software and control of the rover during the test drives. For the Navigation task, this allowed us to improve out traversal route with respect to different terrain types, whether it be wet, dry, rocky or sandy. Our pilots have managed to complete the test drive with a great score, making sure to avoid the danger markers which denote hazardous terrain, following the drive markers which show the best safe path, and probe markers which signify places where a probe needs to be dropped.
            <br></br>
            <br></br>
            For the maintenance task, we have succeeded in completing all the necessary 10 objectives we set out for ourselves to make the process as seamless as possible. This allows for accurate trajectory planning as well as detection of the ArUco QR code markers placed around the competition site. The UR3 robotic arm we are using will have to detect and identify markers, press buttons, pick up and place sensors, and operate a panel which has to be opened and closed. While we are still working on the autonomous mode, all the tasks have been successfully completed in manual mode by our Maintenance team. This gives us the motivation to work harder on the material we deliver in the final qualification and make sure everything is in great shape for the finals.
            <br></br>
            <br></br> 
            Last but not least, out Presentation team has worked tirelessly on our video presentation, which sums up all our work in the different departments and introduces the entire team, and received a perfect score from the ERC judges. It is a great watch for any Makercie enthusiast and can be found on our youtube channel, or under this link.
            <br></br>
            <br></br> 
            Make sure to follow us on our social media for more updates and photos of our progess!
            <br></br>
            <br></br>
            See you in the stars!
            <br></br>
            <br></br>
            Makercie.             

            </p>
            <div class='grid place-content-center mb-4 pt-4 px-12 pb-8'>
              <img src={rank} class = 'rounded-lg h-96 mx-auto'></img>
            </div>
          </div>

        </div>
      </div>
      <SocialPage/>
    </div>
  );
}

export default Competitions;

// Similarly, create other component files for AboutUs, Competitions, Gallery, and JoinOurTeam.
