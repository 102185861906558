import React from 'react';
import './style.css';
import Insta from './components/insta.png';
import Lin from './components/lin.png';
import Fb from './components/facebook.png'
function SocialPage(){

  const redirectToTeamLocation = () => {
      window.open(
        'https://www.google.com/maps/place/Energy+Academy+Europe/@53.2400714,6.5374647,15z/data=!4m2!3m1!1s0x0:0xa451df78fbae4a9f?sa=X&ved=2ahUKEwjJ7IrlxqSBAxXq3wIHHa3VCiYQ_BJ6BAhNEAA&ved=2ahUKEwjJ7IrlxqSBAxXq3wIHHa3VCiYQ_BJ6BAhTEAg',
        '_blank'
      );
  };

  const redirectToWriteEmail = () => {
    window.location.href ='mailto:makercienl@gmail.com';
  };

    return(
      <div class="bg-teal-950">
        <div className='container py-20 mx-auto'>
          <div className='container'>
            <div className='flex justify-center space-x-4'>
              <div className='justify-self-center'>
                <a href="https://www.instagram.com/makercie_team/" target="_blank">
                  <img src={Insta} className='object-contain h-12 w-12'></img>
                </a>
              </div>

              <div className='justify-self-center'>
                <a href="https://nl.linkedin.com/company/makercie-rug" target="_blank">
                  <img src={Lin} className='object-contain h-12 w-12'></img>
                </a>
              </div>

              <div className='justify-self-center'>
                <a href="https://m.facebook.com/p/Makercie-Team-100092329811636/" target="_blank">
                  <img src={Fb} className='object-contain h-12 w-12'></img>
                </a>
              </div>
            </div>

            <div className='grid grid-cols-1 justify-center space-x-4'>
              <div className='justify-self-center' onClick={redirectToTeamLocation}>
                <p className='text-white'> 
                  Nijenborgh 6 
                  <br></br>
                  9747 AG 
                  <br></br>
                  Groningen
                </p>
              </div>

              <div className='justify-self-center' onClick={redirectToWriteEmail}>
                <p className='text-white'>
                  makercienl@gmail.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default SocialPage;

// <div className='socialPage'> 
//         <div className='socialLogos'>
//           <div className='socialElement'>
//             <a href="https://www.instagram.com/makercie_team/" target="_blank">
//             <img src={Insta} className='socialPicture'></img>
//             </a>
//           </div>

//           <div className='socialElement'>
//             <a href="https://nl.linkedin.com/company/makercie-rug" target="_blank">
//             <img src={Lin} className='socialPicture' style={{paddingLeft:"10px"}}></img>
//             </a>
//           </div>

//           <div className='socialElement'>
//             <a href="https://m.facebook.com/p/Makercie-Team-100092329811636/" target="_blank">
//             <img src={Fb} className='socialPicture' style={{paddingLeft:"0px"}}></img>
//             </a>
//           </div>
//         </div>

//         <div className='mapsRedirect' onClick={redirectToTeamLocation}>
//           <p className='socialText'> 
//             Nijenborgh 6 
//             <br></br>
//             9747 AG 
//             <br></br>
//             Groningen
//           </p>
//         </div>
//         <div className='emailAddress' onClick={redirectToWriteEmail}>
//           <p className='socialText'>
//             makercienl@gmail.com
//           </p>
//         </div>
//       </div>